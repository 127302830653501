import React, { useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import mappng from "../../assets/images/map.png";
// On click, the user should indeed be sent to that specific member page

// const UnitsWrapper = styled.svg`
//   mix-blend-mode: multiply;
//   & > g {
//     transition: 0.5s;
//     background-color: var(--white);
//     &:not(#Unclickable):hover,
//     &.locationSelected {
//       cursor: var(--cursor-hover) 0 0, pointer;
//       & > path {
//         opacity: 1;
//         fill: var(--primary);
//       }
//     }
//   }
// `;

const MapSVG = ({
  focusedLocation,
  showCommunityMember,
  handleSetCurrentType,
}) => {
  const locationQuery = useStaticQuery(graphql`
    query {
      allCraftMaplocationDefaultEntry {
        nodes {
          id
          locationName
          locationId
          locationPath
          title
          allowClick
        }
      }
    }
  `);

  // const locationsOfClickable =
  //   locationQuery.allCraftMaplocationDefaultEntry.nodes?.filter(
  //     (node) => node?.allowClick
  //   );
  // const locationsOfUnClickable =
  //   locationQuery.allCraftMaplocationDefaultEntry.nodes?.filter(
  //     (node) => !node?.allowClick
  //   );

  //   console.log("locationsOfUnClickable=>", locationsOfUnClickable)

  useEffect(() => {
    if (focusedLocation && focusedLocation !== "Unclickable") {
      handleChangeLocation(focusedLocation);
    }
  }, [focusedLocation]);

  const handleChangeLocation = (locationTitle) => {
    let allLocations = document.getElementsByClassName("locationSelected");
    let _allLocations = [...allLocations];
    _allLocations &&
      _allLocations?.map((item) => {
        item.classList.remove("locationSelected");
      });
    let element = document.getElementById(locationTitle);
    if (element) {
      element.classList.add("locationSelected");
      handleSetCurrentType(locationTitle);
    }
  };

  const formatTitle = (title) => {
    let _title = "";
    title.split("_").forEach((item) => {
      _title += item + " ";
    });
    return _title;
  };

  const parse = require("html-react-parser");
  // const locationsElementClickable = locationsOfClickable.map(
  //   (node) =>
  //     node.locationPath && (
  //       <g
  //         onMouseMove={(e) => {
  //           let tooltip = document.getElementById("tooltip");
  //           tooltip.innerText = formatTitle(node.title);
  //           tooltip.classList.add("active");
  //           tooltip.style.left = `${e.pageX - 85}px`;
  //           tooltip.style.top = `${e.pageY - 330}px`;
  //         }}
  //         onMouseLeave={() => {
  //           let tooltip = document.getElementById("tooltip");
  //           tooltip.classList.remove("active");
  //         }}
  //         onClick={(e) => {
  //           handleChangeLocation(node?.title);
  //         }}
  //         key={node.id}
  //         id={node.title}
  //       >
  //         {parse(node.locationPath)}
  //       </g>
  //     )
  // );

  function customParse(svgContent) {
    return parse(svgContent, {
      replace: (domNode) => {
        if (domNode.type === 'tag' && domNode.name === 'img') {
          // Prevent <img> conversion; return original <image> element
          domNode.name = 'image';
          return domNode;
        }
      },
    });
  }

  // const locationsElementUnClickable = locationsOfUnClickable.map(
  //   (node) =>
  //     node.locationPath && (
  //       <g key={node.id} id={node.title}>
  //         {customParse(node.locationPath)}
  //       </g>
  //     )
  // );

  const UnitsWrapper = styled.img``
  return (
    <UnitsWrapper
      src={mappng}
    >
    </UnitsWrapper>
  );
};

export default MapSVG;
