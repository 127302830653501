import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import Layout from "../components/Layout";
import ScheduleHero from "../components/ScheduleHero";
import { EventDiv, ScheduleDiv } from "../components/ScheduleStyles/elements";
import { BigButtonSchedule } from "../components/ButtonsSchedule";
import { getDate, getTimeHaveSpace } from "../modules/time";
import NotFoundEvent from "../components/NotFoundEvent";
import { Container } from "../components/HomepageIntro/elements";
import DownloadV2 from "../components/icons/DownloadV2";
import { DownloadButton } from "../components/DownloadButton/elements";
import { AnimatedButton } from "../components/Buttons/elements";
import ProgrammeEventList from "../components/ProgrammeEventList";
import MultipleFilter from "../components/MultipleFilter";

const ScheduleListPage = () => {
  const scheduleQuery = useStaticQuery(graphql`
    {
      pageConfig: craftQafProgrammeQafProgrammeEntry {
        showBannerImages
        programmeTitle
        allowDownloadProgramme
        showFilter
        showTimeFilter
        showEventTiles
        descriptionSeo
        titleSeo
        downloadButtonText
        pdfProgrammeFile {
          id
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              publicURL
            }
          }
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              publicURL
            }
          }
        }
        headerBackground {
          ... on Craft_communityAssets_Asset {
            id
            localFile {
              childImageSharp {
                fluid(quality: 100, maxHeight: 371) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          ... on Craft_qafAssets_Asset {
            id
            localFile {
              childImageSharp {
                fluid(quality: 100, maxHeight: 371) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        startDateFilter
        endDateFilter
        eventFilter {
          ... on Craft_qafEventType_default_Entry {
            id
            title
          }
        }
        locationFilter {
          ... on Craft_maplocation_default_Entry {
            locationId
            locationName
          }
        }
      }
      events: allCraftQafEventsQafEventEntry {
        nodes {
          id
          title
          slug
          subtitle
          eventShortDescription
          headerImage: qafEventHeaderImage {
            ... on Craft_eventsAssets_Asset {
              cardImage: localFile {
                publicURL
                childImageSharp {
                  fixed(width: 4000, fit: COVER, quality: 100) {
                    src
                    ...GatsbyImageSharpFixed
                  }
                }
              }
            }
          }
          qafEventType {
            ... on Craft_qafEventType_default_Entry {
              id
              title
            }
          }
          eventStartDateTime
          eventEndDateTime
          qafEventLocation {
            title
            ... on Craft_participants_default_Entry {
              participantLocation {
                title
              }
              communityMemberOpeningHours
              communityMemberSlug
              communityMemberWebsite
              communityMemberEmailAddress
              communityMemberDescription
              communityMemberContactDetails {
                col1
                col2
              }
              qafParticipantType {
                ... on Craft_qafParticipantType_default_Entry {
                  id
                  title
                }
              }
            }
            ... on Craft_maplocation_default_Entry {
              locationId
              locationName
              title
              allowClick
            }
          }
        }
      }
    }
  `);

  const now = new Date();
  const timeOption = [
    { value: "morning", label: "Morning" },
    { value: "afternoon", label: "Afternoon" },
    { value: "evening", label: "Evening" },
  ];

  let morningEvents = [];
  let afternoonEvents = [];
  let eveningEvents = [];

  const availableEvents = scheduleQuery?.events?.nodes?.filter((item) => {
    const thisDate = new Date(item.eventEndDateTime || item.eventStartDateTime || null);
    return thisDate >= now;
  });

  availableEvents?.map((eventItem) => {
    const startDate = getTimeHaveSpace(eventItem?.eventStartDateTime).split(" ");

    let timeOfStartDate = [];
    if (startDate[0].includes(":")) {
      timeOfStartDate = startDate[0].split(":");
    } else {
      timeOfStartDate = startDate[0].split(".");
    }
    let hourOfStartDate = Number(timeOfStartDate[0]);
    const minuteOfStartDate = Number(timeOfStartDate[1]);
    const suffixesOfStartDate = startDate[1];
    if (hourOfStartDate === 12) hourOfStartDate = 0;
    let startTime = minuteOfStartDate + hourOfStartDate * 60;
    if (suffixesOfStartDate === "pm") {
      startTime += 720;
    }
    const endDate = getTimeHaveSpace(eventItem?.eventEndDateTime ?? eventItem?.eventStartDateTime).split(" ");
    let timeOfEndDate = [];
    if (endDate[0].includes(":")) {
      timeOfEndDate = endDate[0].split(":");
    } else {
      timeOfEndDate = endDate[0].split(".");
    }
    let hourOfEndDate = Number(timeOfEndDate[0]);
    const minuteOfEndDate = Number(timeOfEndDate[1]);
    const suffixesOfEndDate = endDate[1];
    if (hourOfEndDate === 12) hourOfEndDate = 0;
    let endTime = minuteOfEndDate + hourOfEndDate * 60;
    if (suffixesOfEndDate === "pm") {
      endTime += 720;
    }

    if (startTime < 780) {
      morningEvents.push(eventItem);
    }
    if (startTime < 1080 && endTime >= 780) {
      afternoonEvents.push(eventItem);
    }
    if (endTime >= 1081 || startTime >= 1081) {
      eveningEvents.push(eventItem);
    }
  });

  const pageConfig = scheduleQuery?.pageConfig;
  const {
    showBannerImages,
    showFilter,
    showTimeFilter,
    showEventTiles,
    descriptionSeo,
    titleSeo,
    programmeTitle,
    allowDownloadProgramme,
    downloadButtonText,
    pdfProgrammeFile,
    headerBackground,
    startDateFilter,
    endDateFilter,
    eventFilter,
    locationFilter,
  } = pageConfig;

  const getDateFilter = (startDate, endDate) => {
    const days = [];
    if (!startDate || !endDate) {
      return days;
    } else {
      startDate = new Date(startDate);
      endDate = new Date(endDate);
      let currentDate = new Date(startDate.getTime());

      while (currentDate <= endDate) {
        days.push(getDate(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return days;
    }
  };

  const dateFilter = getDateFilter(startDateFilter, endDateFilter);

  const filterList = [];

  if (dateFilter?.length) {
    filterList.push({
      id: "date",
      label: "Date",
      options: dateFilter?.map((option) => ({
        value: option,
        label: option,
      })),
    });
  }

  if(showTimeFilter) {
    filterList.push({
      id: "time",
      label: "Time",
      options: timeOption,
    });
  }

  if (eventFilter?.length) {
    filterList.push({
      id: "event",
      label: "Event",
      options: eventFilter?.map((option) => ({
        value: option?.id,
        label: option?.title,
      })),
    });
  }
  
  if (locationFilter?.length) {
    filterList.push({
      id: "location",
      label: "Location",
      options: locationFilter?.map((option) => ({
        value: option?.locationId,
        label: option?.locationName,
      })),
    });
  }

  const [end, setEnd] = useState(15);
  const [eventList, setEventList] = useState(availableEvents);

  const handleClickLoadMore = () => {
    setEnd((pre) => pre + 15);
  };

  const onFilter = (selectedFilter) => {
    let matchEvents = availableEvents;
    selectedFilter?.map((filterType) => {
      if (filterType?.id === "date") {
        const matchLocalEvents = new Set();
        filterType?.selectedOptions?.forEach((option) => {
          const dateFilter = new Date(`${option?.value}`).getTime();
          const localEvent = matchEvents?.filter((eventItem) => {
            const startDate = new Date(getDate(eventItem?.eventStartDateTime));
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date(eventItem?.eventEndDateTime ? getDate(eventItem?.eventEndDateTime) : getDate(eventItem?.eventStartDateTime));
            endDate.setHours(23, 59, 59, 999);
            if (startDate.getTime() <= dateFilter && dateFilter <= endDate.getTime()) {
              return true;
            } else {
              return false;
            }
          });
          localEvent?.forEach(matchLocalEvents.add, matchLocalEvents);
        });
        if (filterType?.selectedOptions?.length) {
          matchEvents = matchEvents?.filter((eventItem) => matchLocalEvents?.has(eventItem));
        }
      }
      if (filterType?.id === "time") {
        const matchLocalEvents = new Set();
        filterType?.selectedOptions?.forEach((option) => {
          switch (option?.value) {
            case "morning":
              morningEvents?.forEach(matchLocalEvents.add, matchLocalEvents);
              break;
            case "afternoon":
              afternoonEvents?.forEach(matchLocalEvents.add, matchLocalEvents);
              break;
            case "evening":
              eveningEvents?.forEach(matchLocalEvents.add, matchLocalEvents);
              break;
          }
        });
        if (filterType?.selectedOptions?.length) {
          matchEvents = matchEvents?.filter((eventItem) => matchLocalEvents?.has(eventItem));
        }
      }
      if (filterType?.id === "event") {
        const matchLocalEvents = new Set();
        filterType?.selectedOptions?.forEach((option) => {
          const localEvent = matchEvents?.filter((eventItem) => eventItem?.qafEventType?.map((type) => type?.id)?.includes(option?.value));
          localEvent?.forEach(matchLocalEvents.add, matchLocalEvents);
        });
        if (filterType?.selectedOptions?.length) {
          matchEvents = matchEvents?.filter((eventItem) => matchLocalEvents?.has(eventItem));
        }
      }
      if (filterType?.id === "location") {
        const matchLocalEvents = new Set();
        filterType?.selectedOptions?.forEach((option) => {
          const localEvent = matchEvents.filter((eventItem) =>
            eventItem?.qafEventLocation?.map((location) => location?.locationId)?.includes(option?.value)
          );
          localEvent?.forEach(matchLocalEvents.add, matchLocalEvents);
        });
        if (filterType?.selectedOptions?.length) {
          matchEvents = matchEvents?.filter((eventItem) => matchLocalEvents?.has(eventItem));
        }
      }
    });

    setEventList([...matchEvents]);
  };

  return (
    <Layout
      backgroundColor={"var(--bg-color)"}
      skipHeader
      headerBlack={true}
      title={titleSeo || "Programme - Quoz Arts Fest"}
      SEODescription={descriptionSeo}
    >
      {showBannerImages ? (
        <ScheduleDiv background={headerBackground?.length ? headerBackground?.[0]?.localFile?.childImageSharp?.fluid?.src : null}>
          <ScheduleHero topLine={programmeTitle} />
          {allowDownloadProgramme && pdfProgrammeFile.length && pdfProgrammeFile[0].localFile ? (
            <Container style={{ padding: 0 }}>
              <a href={pdfProgrammeFile[0].localFile?.publicURL} download="Programme" target="_blank">
                <AnimatedButton>
                  <DownloadButton>
                    <DownloadV2 color="#101820" />
                    {downloadButtonText ?? "Programme"}
                  </DownloadButton>
                </AnimatedButton>
              </a>
            </Container>
          ) : null}
        </ScheduleDiv>
      ) : null}

      {showFilter ? <MultipleFilter filterList={filterList} onFilter={onFilter} /> : null}

      {showEventTiles ? (
        <div>
          <EventDiv isScheduleList>
            <ProgrammeEventList events={eventList?.length ? eventList.slice(0, end) : []} />
            {eventList.length === 0 ? <NotFoundEvent /> : null}
            {eventList?.length > end ? (
              <AnimatedButton style={{ margin: "30px auto 0 auto" }}>
                <BigButtonSchedule style={{ margin: 0 }} backgroundColor="var(--primary)" color="var(--black) " onClick={handleClickLoadMore}>
                  Load more
                </BigButtonSchedule>
              </AnimatedButton>
            ) : null}
          </EventDiv>
        </div>
      ) : null}
    </Layout>
  );
};

export default ScheduleListPage;
