import styled from "styled-components";

export const MapContainer = styled.div`
  background-color: var(--bg-color);
  width: 100%;
`;

export const MapDiv = styled.div`
  margin-top: 75px;
  background-color: var(--white);
  position: relative;
  // height: 860px;
  height: 1335px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  & > img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 1440px;
  }
  & > svg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 1430px;
    /* transform: scale(0.9); */
    transform-origin: top;
  }
  .enabled{
    fill: var(--primary);
    cursor: var(--cursor-hover) 0 0, pointer;
  }
  .tooltip {
    pointer-events: none;
    position: absolute;
    font-size: 18px;
    text-align: center;
    background: rgba(47, 237, 28, 0.7);
    padding: 0px 8px;
    z-index: 5;
    line-height: 36px;
    margin: 0 auto;
    color: #000000;
    +transform(translateX(-50%));
    display: none;
    &.active {
      display: block;
    }
    // &:after {
    //   content: '';
    //   position: absolute;
    //   left: 50%;
    //   top: 100%;
    //   width: 0;
    //   height: 0;
    //   margin-left: -10px;
    //   border-left: 10px solid transparent;
    //   border-right: 10px solid transparent;
    //   border-top: 10px solid var(--primary);
    // }
  }
  overflow-y: hidden;
  @media (max-width: 1439px) {
    overflow-x: scroll;
  }
  @media (max-width: 767px) {
    .tooltip {
      display: none !important;
    }
  }
`;

export const FocusWindow = styled.div`
  border: 2px solid black;
  position: absolute;
  right: 0;
  top: 0;
`;

export const FakeMapDiv = styled.div`
  max-width: var(--width);
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-x: scroll;
  & img {
    width: 1440px;
    height: auto;
  }
`;
